import { type ReactNode, createContext } from 'react'

interface ShopContextProps {
  currency?: string
}

interface ShopContextProviderProps {
  currency?: string
  children: ReactNode
}

export const ShopContext = createContext<ShopContextProps>({})

export const ShopContextProvider = ({
  currency,
  children,
}: ShopContextProviderProps) => {
  return (
    <ShopContext.Provider
      value={{
        currency,
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}
