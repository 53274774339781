import { type ReactNode, createContext, useMemo } from 'react'

import {
  type SanitySiteFragment,
  type SanityStrings,
} from '@data/sanity/queries/types/site'

type StringsContextProps = SanityStrings

const emptySiteStrings: StringsContextProps = {
  loadingPageTitle: '',
  languageSwitch: '',
  skipToContent: '',
  goHomeLabel: '',
  mobileMenuLabel: '',
  carouselCounterText: '',
  carouselLeftArrowLabel: '',
  carouselRightArrowLabel: '',
  carouselDotLabel: '',
  buttonAccept: '',
  buttonLearnMore: '',
  buttonSubmit: '',
  buttonTryAgain: '',
  buttonLoadMore: '',
  emailAddress: '',
  emailAddressPlaceholder: '',
  emailMissing: '',
  emailInvalid: '',
  firstName: '',
  firstNamePlaceholder: '',
  firstNameMissing: '',
  lastName: '',
  lastNamePlaceholder: '',
  lastNameMissing: '',
  fullName: '',
  fullNamePlaceholder: '',
  fullNameMissing: '',
  phoneNumber: '',
  phoneNumberPlaceholder: '',
  phoneNumberMissing: '',
  phoneNumberInvalid: '',
  company: '',
  companyPlaceholder: '',
  message: '',
  messagePlaceholder: '',
  messageMissing: '',
  blogPostReadMore: '',
  blogPostBy: '',
}

export const StringsContext =
  createContext<StringsContextProps>(emptySiteStrings)

interface StringsContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const StringsContextProvider = ({
  site,
  children,
}: StringsContextProviderProps) => {
  const strings = useMemo(
    () => ({
      ...emptySiteStrings,
      ...site.strings,
    }),
    [site.strings]
  )

  return (
    <StringsContext.Provider value={strings}>
      {children}
    </StringsContext.Provider>
  )
}
