export const contactEventName = 'Contact'
export const newsletterSignUpEventName = 'Newsletter sign up'

/**
 * Triggers pageview events in GTM and GA.
 */
export const triggerPageviewEvent = () => {
  // Push Google Tag Manager data layer event
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'Pageview',
    pagePath: window.location.pathname,
    pageTitle: document.title,
  })

  // Push Google Analytics event
  if (window['ga']) {
    window.ga('set', {
      location: location.href,
      page: location.pathname,
      title: document.title,
    })
    window.ga('send', 'pageview')
  }
}

/**
 * Triggers an event in Google Tag Manager.
 */
export const triggerGoogleTagManagerEvent = (eventName: string) => {
  window.dataLayer.push({
    event: eventName,
    pagePath: window.location.pathname,
    pageTitle: document.title,
  })
}
